<template>
  <div class="index">
    <template v-if="firstLoading">
      <div class="skeleton_banner"></div>
      <div class="w_sld_react_1210 adv_04_wrap skeleton">
        <div class="floor_title">
          <h2>
            <font>&nbsp;</font>
            <span></span>
            <font>&nbsp;</font>
          </h2>
        </div>
        <div class="floor_goods">
          <div class="item" v-for="(item_main,index_main) in [1,2,3,4,5,6,7,8,9,10]" v-bind:key="index_main">
            <div class="wrap">
              <a href="javascript:void(0)" class="example_text"></a>
              <p class="title">
                <a href="javascript:void(0)">{{item_main}}</a>
              </p>
              <p class="price">
                <span></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <SldDiy v-if="decorateData.data.length" :decorateData="decorateData" @adv19="handleAdv19" />
    <OpenScreen @closeScreen="closeScreen" v-if="show_open_screen" :openScreenData="openScreenData.data"></OpenScreen>
    <!-- 空页面 start-->
    <SldCommonEmpty v-if="!firstLoading&&!decorateData.data.length" totalHeight="900" paddingTop="250"
      tip="登录超时或未注册，建议进行如下操作：1、退出当前账号，并重新登录；2、注册商城账号并认证合作商资质。" />
    <!-- 空页面 end-->
    <!-- 证件照过期框 -->
    <el-dialog
        v-model="showCoupon"
        lock-scroll="false"
        width=700px
    >
      <div  @click="goShowCoupon()">
        <el-image :src="'https://image.mallcy.cn/java/bbc/mobile/pcNewcomerCoupon.jpg'" mode="aspectFit"></el-image>
      </div>
    </el-dialog>
    <!-- 资质审核未通过 -->
    <el-dialog
        v-model="showManagement"
        lock-scroll="false"
        top=300px
        width=540px
    >
      <div  @click="goManagement()">
        <el-image :src="'https://image.cy-pharm.com/java/bbc/mobile/business-qualifications.png'" mode="aspectFit"></el-image>
      </div>
    </el-dialog>
    <!-- 证件照过期框 -->
    <el-dialog
        title="资质过期无法下单"
        v-model="showQyCustlicb"
        width="480px"
        lock-scroll="false"
        top="10%"
    >
      <div class="qyCustlicb_con">
        <div class="qyCustlicb_item">
          <span>以下资质信息已过期：<br></span>
          <span style="whiteSpace: pre-wrap;">{{ qyCustlicbmsg }}</span>
          <span>请重新提交新资质，谢谢！</span>
        </div>
        <div class="flex_row_center_center" style="color:#ff1244 ;font-size: 22px;"
             @click="goManagement()">{{'更新资质'}}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { reactive, getCurrentInstance, ref, onMounted } from "vue";
  import { useRouter } from 'vue-router';
  import SldDiy from "../../components/SldDiy";
  import OpenScreen from "../../components/OpenScreen";
  import { useStore } from "vuex";
  import SldCommonEmpty from "../../components/SldCommonEmpty";
  import { getQueryVariable } from '@/utils/common.js'

  export default {
    name: "index",
    components: {
      SldDiy,
      OpenScreen,
      SldCommonEmpty
    },
    setup() {
      const router = useRouter()
      const adv_21_right_icon = require("../../assets/decorate/right_more_icon.png");
      const adv_24_let_default_pic = require("../../assets/decorate/adv_24_let_default_pic.png");
      const decorateData = reactive({ data: [] }); //装修数据
      const openScreenData = reactive({ data: {} }); //开屏数据
      const firstLoading = ref(true); //是否第一次加载
      const { proxy } = getCurrentInstance();
      const show_open_screen = ref(false);
      const store = useStore();
      const showCoupon = ref(false)
      const showManagement = ref(false)
      const showQyCustlicb = ref(false)
      const qyCustlicbmsg =ref('')
      const grade = reactive({data:{}})
      const getInitData = () => {
        proxy.$get("v3/system/front/pcDeco/index").then(res => {
          firstLoading.value = false;
          if (res.state == 200) {
            if (res.data.length > 0) {
              decorateData.data = res.data;
              decorateData.data.map(item => {
                item.json_data = JSON.parse(item.json);
                if (item.json_data && item.json_data.type == 'adv_19') {
                  item.json_data.data.map(child => {
                    child.cur_tab = 0;
                  })
                }
                if (item.json_data && item.json_data.type == 'main_banner') {
                  item.json_data.data = item.json_data.data.filter(i => i.status == 1)
                }
              });
            }else {
              router.push({
                path: "/login"
              });
            }
          }
        });
      };
      const getOpenScreen = () => {
        proxy.$get("v3/system/front/deco/firstAdv").then(res => {
          if (res.state == 200) {
            openScreenData.data = JSON.parse(res.data.data);
            console.log(openScreenData.data)
            //企业类型广告是否展示
            if (res.data.homepageAds === 'Y') {
              if (openScreenData.data.imgUrl && openScreenData.data.show_switch) {
                if (openScreenData.data.show_radio_sele === "one") {
                  if (res.data.homepageAdsBallPc === null || res.data.homepageAdsBallPc === 0) {
                    show_open_screen.value = true;
                    document.body.style.overflow = "hidden";
                  }
                } else if (openScreenData.data.show_radio_sele === "more") {
                show_open_screen.value = true;
                document.body.style.overflow = "hidden";
                }
              }
            }
          }
        });
      };
      const closeScreen = () => {
        show_open_screen.value = false;
        document.body.style.overflow = "";
      };
      router.beforeEach((to, from, next) => {
        document.body.style.overflow = "";
        next()
      })
      getOpenScreen();
      getInitData();
      const handleAdv19 = (data) => {
        let tmp_data = decorateData.data.filter(item => item.dataId == data.dataId)[0];
        tmp_data.json_data.data[data.left_or_right_index]['cur_tab'] = data.tab_index;
      }

      const goShowCoupon = () => {
        router.push({
          path: "/member/coupon"
        });
      };

      const goManagement = () => {
        router.push({
          path: "/member/management/list"
        });
      };
      const gettouxiang = ()=>{
        proxy
            .$post("/v3/member/front/member/getGrade")
            .then(res => {
              if (res.state == 200) {
                grade.data = res.data
                if(grade.data.enterpriseQualificationState!=2){
                  showManagement.value =true;
                }
                if(grade.data.nqcmsg!=null){
                  qyCustlicbmsg.value=grade.data.nqcmsg
                  showQyCustlicb.value = true
                }
              }
            })
            .catch(() => {
              //异常处理
            });
      }

      onMounted(() => {
        // 首次优惠券提醒
        if(store.state.memberInfo.newcomerCoupon != null && store.state.memberInfo.newcomerCoupon==1){
          showCoupon.value =true;
          let params = {
            memberId: store.state.memberInfo.memberId,
            newcomerCoupon: 2
          }
          proxy.$post('/v3/member/front/member/updateInfo', params).then(res => {
            if (res.state == 200) {
              //获取用户信息，并同步信息到vuex
              proxy.$get("/v3/member/front/member/getInfo").then(res => {
                if (res.state == 200) {
                  store.commit("updateMemberInfo", res.data);
                }
              });
            }
          });
        }
        gettouxiang()
      })

      return {
        adv_21_right_icon,
        adv_24_let_default_pic,
        decorateData,
        firstLoading,
        show_open_screen,
        openScreenData,
        handleAdv19,
        showCoupon,
        goShowCoupon,
        closeScreen,
        gettouxiang,
        goManagement,
        showManagement,
        showQyCustlicb,
        qyCustlicbmsg,
        grade
      };
    }
  };
</script>
<style lang="scss" scoped>
  @import "../../style/decorate.scss";

  .index {
    background: #f8f8f8;
    padding-bottom: 10px;
  }

  .skeleton_banner {
    width: 100%;
    height: 470px;
    background: $colorSkeleton;
    margin-bottom: 10px;
  }

  .skeleton {
    &.adv_04_wrap .floor_title h2 span {
      background: $colorSkeleton;
      display: inline-block;
      width: 200px;
    }

    &.adv_04_wrap .floor_goods .item .wrap .title {
      background: $colorSkeleton;
      width: 100%;

      a {
        color: transparent;
      }
    }

    &.adv_04_wrap .floor_goods .item .wrap .price {
      width: 100%;
      height: 25px;
    }

    &.adv_04_wrap .floor_goods .item .wrap .price span {
      display: inline-block;
      width: 70px;
      height: 100%;
      background: $colorSkeleton;
    }
  }
</style>
