<template>
    <div class="sld_login">
        <div class="sld_login_header">
            <div class="content" v-if="type!='afterSales'">
                <router-link tag="a" class="l_logo" :to="`/index`">
                    <img src="../../../assets/argumentlogo.png" class="img" />  
                </router-link>
                <div class="r_register_wrap">
                    {{L['我已知悉？']}}
                    <a class="go_register_btn" @click="goRegister">
                        {{L['去注册']}}
                    </a>
                </div>
            </div>

            <!-- 售后条款开始 -->
            <div class="content" v-if="type =='afterSales'">
                <router-link tag="a" class="l_logo" :to="`/index`">
                    <img src="../../../assets/common_top_logo.png" class="img" />
                </router-link>
                <div class="r_register_wrap">
                    {{'我已阅读？'}}
                    <a class="go_register_btn" @click="goBack">
                        {{L['返回']}}
                    </a>
                </div>
            </div>
            <!-- 售后条款结束 -->
        </div>

        <!-- 注册协议开始 -->
        <div class="agreement_container" v-if="type!='afterSales'">
            <h2 class="agreement_title">{{agreeContent.title}}</h2>
            <pre class="agreement_content" v-html="agreeContent.content"></pre>
        </div>
        <!-- 注册协议结束-->

        <!-- 售后条款内容开始 -->
        <div class="agreement_container" v-if="type =='afterSales'">
            <h2 class="agreement_title">{{agreeContent.title}}</h2>
            <pre class="agreement_content" v-html="agreeContent.content"></pre>
        </div>
        <!-- 售后条款内容结束 -->
    </div>
</template>

<script>
    import { useRouter,useRoute } from 'vue-router'
    import { getCurrentInstance, reactive, onMounted,ref } from 'vue';

    export default {
        name: "Agreement",
        setup() {
            const router = useRouter()
            const route = useRoute()
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const type = ref('') //显示协议类型   afterSales为售后条款
            const agreeContent = reactive({
                content: '',
                title: ''
            })
            const agreementCode = reactive({
                agreementCode: 'register_agreement'
            })
            const getInitData = () => {
                proxy.$get('/v3/system/front/agreement/detail', agreementCode).then(res => {
                    if (res.state == 200) {
                        agreeContent.content = res.data.content
                        agreeContent.title = res.data.title
                    }
                })
            }

            const goRegister = () => {
                router.push({
                    path: '/register'
                })
            }
            //返回售后页面函数
            const goBack = () => {
                const orderState = route.query.orderState
                const orderProductId = route.query.orderProductId
                const flag = 1
                // router.push('/member/order/refund/apply?orderState=' + orderState + '&orderProductId=' + orderProductId + )
                router.replace({
                    path:'/member/order/refund/apply',
                    query:{
                        orderState,
                        orderProductId,
                        flag
                    }
                })
                // router.go(-1)
            }

            onMounted(() => {
                if(route.query.type != 'afterSales'){
                    getInitData()
                }
                type.value = route.query.type
                if(type.value =='afterSales'){
                    proxy.$get('/v3/system/front/agreement/detail?agreementCode=after_sales_rules_agreement').then(res => {
                        if(res.state == 200){
                            agreeContent.content = res.data.content
                            agreeContent.title = res.data.title
                        }
                    })
                }
            })

            return { L, agreeContent, goRegister,type,goBack }
        },
    };
</script>
<style lang="scss" scoped>
    @import '../../../style/agreement.scss';
    .agreement_container{
        width: 800px;
        
    }
    .agreement_content{
        font-size: 15px;
        line-height: 35px;
        white-space:normal;
        word-break:break-all;
    }
    
</style>
