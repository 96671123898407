<template>
  <div class="sld_Business">
    <div class="sld_Business_bodyer">
      <div class="Business_form">
        <MemberTitle :memberTitle="'经营信息'"/>
      <template v-if="isApply==2">
        <div class="article">
          <div class="checkState" v-if="disabledFlag">
            <span v-if="stateValues_type==1">编辑内容审核中</span>
            <span v-if="stateValues_type==3">编辑内容审核失败</span>
            <span v-if="refuseReasons">拒绝原因：{{refuseReasons}}</span>
          </div>
          <div class="region">
            <div class="line">
              <div>
                <!-- <span>*</span> -->
                企业全称：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  maxlength="20"
                  placeholder="请输入企业全称"
                  v-model="memberList.enterpriseName"
                  :disabled="disabledFlag"
                />
              </div>
            </div>
<!--            <div class="line">-->
<!--              <div>-->
<!--                &lt;!&ndash; <span>*</span> &ndash;&gt;-->
<!--                收货人：-->
<!--              </div>-->
<!--              <div>-->
<!--                <input-->
<!--                  :class="{'inp_o':disabledFlag}"-->
<!--                  type="text"-->
<!--                  maxlength="20"-->
<!--                  :placeholder="disabledFlag?'&#45;&#45;':'请输入收货人姓名'"-->
<!--                  v-model="memberListInfo.receiveName"-->
<!--                  :disabled="disabledFlag"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="line">-->
<!--              <div>-->
<!--                &lt;!&ndash; <span>*</span> &ndash;&gt;-->
<!--                收货电话：-->
<!--              </div>-->
<!--              <div>-->
<!--                <input-->
<!--                  :class="{'inp_o':disabledFlag}"-->
<!--                  type="text"-->
<!--                  maxlength="20"-->
<!--                  :placeholder="disabledFlag?'&#45;&#45;':'请输入收货电话'"-->
<!--                  v-model="memberListInfo.contactMobile"-->
<!--                  :disabled="disabledFlag"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
            <div class="line">
              <div>
                <!-- <span>*</span> -->
                所在地区：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  maxlength="20"
                  placeholder="--"
                  v-model="memberListInfo_address"
                  v-if="disabledFlag"
                />
                <el-cascader  v-else class="region_inp" ref="cascaderAddr" v-model="memberListInfo.area" :options="areaDataArray" placeholder="请选择所在地区"
            :props="cascaderProps" readonly="true"></el-cascader>
              </div>
            </div>
            <div class="line">
              <div>
                <!-- <span>*</span> -->
                详细地址：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  maxlength="40"
                  :placeholder="disabledFlag?'--':'请输入详细地址'"
                  v-model="memberListInfo.addressDetail"
                  :disabled="disabledFlag"
                />
              </div>
            </div>
            <!-- <div class="line">
              <div>
                单位地址：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  maxlength="20"
                  :disabled="disabledFlag"
                  placeholder="请输入单位地址"
                  v-model="memberList.enterpriseAddress"
                />
              </div>
            </div>
            <div class="line">
              <div>
                发票抬头：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  :placeholder="memberList.invoiceTitle?memberList.invoiceTitle:disabledFlag?'--':'请输入发票抬头'"
                  v-model="memberList.invoiceTitle"
                />
              </div>
            </div>
            <div class="line">
              <div>
                纳税人识别号：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  v-model="memberList.taxCode"
                />
              </div>
            </div>
            <div class="line">
              <div>
                注册地址：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  v-model="memberList.registerAddr"
                />
              </div>
            </div>
            <div class="line">
              <div>
                注册电话：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  v-model="memberList.registerPhone"
                />
              </div>
            </div>
            <div class="line">
              <div>
                开户银行：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  v-model="memberList.bankName"
                />
              </div>
            </div>
            <div class="line">
              <div>
                银行账户：
              </div>
              <div>
                <input
                  :class="{'inp_o':disabledFlag}"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  v-model="memberList.bankAccount"
                />
              </div>
            </div>
            <div class="line" v-if="disabledFlag">
              <div>
                收票人姓名：
              </div>
              <div>
                <input
                  class="inp_o"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  v-model="memberList.receiverName"
                />
              </div>
            </div>
            <div class="line" v-if="disabledFlag">
              <div>
                收票人电话：
              </div>
              <div>
                <input
                  class="inp_o"
                  type="text"
                  :disabled="disabledFlag"
                  maxlength="40"
                  v-model="memberList.receiverMobile"
                />
              </div>
            </div>
            <div class="line" v-if="disabledFlag">
              <div>
                收票人地址：
              </div>
              <div>
                <input
                  class="inp_o"
                  type="text"
                  maxlength="40"
                  :disabled="disabledFlag"
                  v-model="memberList.receiverAddress"
                />
              </div>
            </div> -->
          </div>
        <div class="submit_qualification">
          <p>
            <span class="qualification" >请确保上传图片能够清晰辨识名称、地址、日期、证号等关键信息，以免因核对失败耽误您的宝贵时间。</span>
          </p>
        </div>
          <!-- 审核项开始 -->
          <div class="region" v-for="(item,index) in dynamic.data.enterpriseQualificationListVOS" :key="index">
            <div class="line_pushPic" v-if="item.formatType==1">
              <div style="margin-top: 30px;padding-right: 5px;"><span v-html="item.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
              <div >
                <div :class="{'line_pushPic_box':disabledFlag}" class="line_pushPic_box_o">
                  <img id="img1" :src="item.value2"  style="height:127px" v-if="!disabledFlag&&item.value2"/>
                  <img id="img1" :src="item.value2"  style="height:127px" v-if="disabledFlag"/>
                  <img src="@/assets/hlight.png" alt="" class="line_pushPic_close" v-if="!disabledFlag&&item.value2" @click="close_img(index,-1)">
                </div>
                <div v-if="!disabledFlag">
                  <!-- <div class="add" v-if="!tempSrc"></div> -->
                  <div class="add" v-if="!item.value2"></div>
                  <input
                    type="file"
                    ref="file2"
                    name="check_img_url"
                    :data-index="index"
                    :data-ind="-1"
                    v-if="!item.image"
                    @change="changepic($event)"
                  />
                </div>
              </div>
            </div>
            <div class="line" v-if="item.formatType==1&&item.template">
              <div>模版：</div>
              <div class="tem_cla" @click="go_tem(item.template)">案例模版</div>
            </div>
            <div class="line" v-if="item.formatType==2">
              <div><span v-html="item.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
              <div>
                <input
                  type="text"
                  :class="{'inp_o':disabledFlag}"
                  maxlength="20"
                  :placeholder="'请输入' + item.enterpriseQualificationName"
                  v-model="item.value1"
                  :disabled="disabledFlag"
                />
              </div>
            </div>
            <div class="line"  v-if="item.formatType==3">
              <div>{{item.enterpriseQualificationName}}：</div>
              <div>
               <input
                  type="text"
                  maxlength="20"
                  v-model="item.value2"
                  disabled
                  style="border:0"
                  v-if="disabledFlag&&item.value1==1"
                />
                <p class="line_p" v-if="disabledFlag&&item.value1==2">长期</p>
                <el-config-provider :locale="locale" v-if="!disabledFlag">
                  <el-date-picker
                    v-model="item.value2"
                    type="date"
                    placeholder="选择日期"
                    style="width: 355px"
                    value-format="YYYY-MM-DD"
                    @change="cardInp($event,index,-1)"
                    :locale="locale"
                  >
                  </el-date-picker>
                </el-config-provider>
                <el-checkbox size="large" v-if="!disabledFlag" @change="aptit_radio($event,index,-1)" v-model="item.flag" :data-index="index" :checked="item.value1==2">长期</el-checkbox>
              </div>
            </div>
            <div v-for="(it,ind) in item.memberSupplyDTOList" :key="ind">
              <div class="line_pushPic" v-if="it.formatType==1">
                <div><span v-html="it.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
                <div >
                  <div :class="{'line_pushPic_box':disabledFlag}" class="line_pushPic_box_o">
                    <img id="img1" :src="it.value2"  style="height:127px" v-if="!disabledFlag&&it.value2"/>
                    <img id="img1" :src="it.value2"  style="height:127px" v-if="disabledFlag"/>
                    <img src="@/assets/hlight.png" alt="" class="line_pushPic_close" v-if="!disabledFlag&&it.value2" @click="close_img(index,ind)">
                  </div>
                  <div v-if="!disabledFlag">
                    <!-- <div class="add" v-if="!tempSrc"></div> -->
                    <div class="add" v-if="!it.value2"></div>
                    <input
                      type="file"
                      ref="file2"
                      name="check_img_url"
                      :data-index="index"
                      :data-ind="ind"
                      v-if="!it.image"
                      @change="changepic($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="line" v-if="it.formatType==2">
                <div><span v-html="it.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
                <div>
                  <input
                    type="text"
                    :class="{'inp_o':disabledFlag}"
                    maxlength="20"
                    :placeholder="'请输入' + it.enterpriseQualificationName"
                    v-model="it.value1"
                    :disabled="disabledFlag"
                  />
                </div>
              </div>
              <div class="line"  v-if="it.formatType==3">
                <div>{{it.enterpriseQualificationName}}：</div>
                <div>
                <input
                    type="text"
                    maxlength="20"
                    v-model="it.value2"
                    disabled
                    style="border:0"
                    v-if="disabledFlag&&it.value1==1"
                />
                <p class="line_p" v-if="disabledFlag&&it.value1==2">长期</p>

                  <el-config-provider :locale="locale" v-if="!disabledFlag">
                    <el-date-picker
                      v-model="it.value2"
                      type="date"
                      placeholder="选择日期"
                      style="width: 355px"
                      value-format="YYYY-MM-DD"
                      @change="cardInp($event,index,ind)"
                      :locale="locale"
                    >
                    </el-date-picker>
                  </el-config-provider>
                  <el-checkbox size="large" v-if="!disabledFlag" @change="aptit_radio($event,index,ind)" v-model="it.flag" :data-index="index" :checked="it.value1==2">长期</el-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- 审核项结束 -->
          <!-- <div class="submit" @click="goToPage('/businessInfo')" v-if="stateCode == 3">
            {{'编辑'}}
          </div> -->
          <div class="submit" style="margin-bottom: 100px; border-radius: 10px;" @click="goToPagez" v-if="disabledFlag&&stateValues_type!=1">
            {{'编辑'}}
          </div>
          <div class="submit"  style="margin-bottom: 100px; border-radius: 10px;" v-if="!disabledFlag&&stateValues_type!=1&&!gologin_flag" @click="submit" >
            提交审核
          </div>
          <div class="submit"   style="background-color: #ddd; cursor: not-allowed;" v-if="gologin_flag"  >
            提交中
          </div>
        </div>
      </template>

      <!-- 没有提交过经营信息显示开始 -->
        <template v-if="isApply==0">
          <div class="article">
            <span class="noapply_info">还未提交过经营信息，请点击按钮去提交经营信息</span>
            <button class="apply_btn" @click="gotoApplyInfo(-1)">去提交经营信息</button>
         </div>
        </template>
      <!-- 没有提交过经营信息显示结束 -->
       <!-- 已经提交但经营信息未审核显示开始 -->
       <template v-if="isApply==1">
         <div class="article">
            <span class="noapply_info">初次审核还未通过，请点击按钮去查看审核状态</span>
            <button class="apply_btn" @click="gotoApplyInfo(1)">去查看审核状态</button>
         </div>
        </template>
      <!-- 已经提交但经营信息未审核显示开始 -->
      </div>
    </div>
  </div>
</template>
<script>
import {ElMessage, ElMessageBox} from "element-plus";
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import { apiUrl } from "../../utils/config";
import axios from "axios";
import { checkMobile, checkPhone } from "../../utils/common"; //导入表单验证函数
import {useRoute,useRouter} from 'vue-router'
import MemberTitle from '../../components/MemberTitle.vue'
import areaData from "../../assets/area.json";

export default {
  name: "LoginIndex",
  components:{MemberTitle},
  setup() {
    const tempSrc = ref("");
    const imgSrc = ref("");
    const img3Src = ref("");
    const avatar = ref(""); //用户头像
    const value1 = ref("");  //工商执照有效期
    const value2 = ref("");  //药品营业许可证有效期
    const value3 = ref("");  //法人委托书有效期
    const router = useRouter()
    const memberListInfo = reactive({
      receiveName:'',//收货人
      contactMobile: '', //收货电话
      addressDetail: '', //详细地址
      area:[],
    })
    const memberListInfo_address = ref('')
    const areaDataArray = areaData;
    const cascaderProps = { label: "regionName", value: "regionCode" };
    const cascaderAddr = ref(null);
    const route = useRoute
    const tirggerFile = (event) => {
      var file = event.target.files;
      var reader = new FileReader(); //读取文件
      reader.readAsDataURL(file[0]);
      reader.onload = function () {
        document.getElementById("img1").src = reader.result;
      };
    };
    const tirggerFile2 = (event) => {
      var file = event.target.files;
      var reader2 = new FileReader(); //读取文件
      reader2.readAsDataURL(file[0]);
      reader2.onload = function () {
        document.getElementById("img2").src = reader2.result;
      };
    };
    const tirggerFile3 = (event) => {
      var file = event.target.files;
      var reader3 = new FileReader(); //读取文件
      reader3.readAsDataURL(file[0]);
      reader3.onload = function () {
        document.getElementById("img3").src = reader3.result;
      };
    };


    //第二个
    const changepic1 = (e) => {
      console.log(e)
      //上传头像
      let newsrc = new FileReader(); //读取文件
      let data = new FormData();
      data.append("file", e.target.files[0]); //删除了name
      data.append("source", "evaluate");
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      let url = apiUrl + "/v3/oss/common/upload";
      if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
        imgSrc.value = newsrc;
        axios.post(url, data, config).then((res) => {
          if (res.status === 200) {
            ElMessage.success("上传图片成功");
            imgSrc.value = res.data.data.url;
            // avatar.value = res.data.data.path;
          } else {
            ElMessage.warning("上传图片失败");
          }
        });
      } else {
        ElMessage.warning("图像应小于4MB");
        // imgSrc.value = avatar.value;
      }
    };
    const go_tem = (url) => {
        window.open(url)
    }

    //第三个
    const changepic2 = (e) => {
      //上传头像
      let newsrc = new FileReader(); //读取文件
      let data = new FormData();
      data.append("file", e.target.files[0]); //删除了name
      data.append("source", "evaluate");
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      let url = apiUrl + "/v3/oss/common/upload";
      if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
        img3Src.value = newsrc;
        axios.post(url, data, config).then((res) => {
          if (res.status === 200) {
            ElMessage.success("上传图片成功");
            img3Src.value = res.data.data.url;
            // avatar.value = res.data.data.path;
          } else {
            ElMessage.warning("上传图片失败");
          }
        });
      } else {
        ElMessage.warning("图像应小于4MB");
        // img3Src.value = avatar.value;
      }
    };

    const keyEnter = ref(true);
    const rander = ref(""); //用户名
    const rander2 = ref(""); //用户名
    const rander3 = ref(""); //用户名

    const company = ref(""); //用户名
    const receive = ref(""); //用户名
    const contactphone = ref(""); //用户名
    const clearInputVal = ref(""); //用户名
    const addressDetails = ref(""); //收获地址
    const cardNumber1 = ref(""); //用户名
    const cardNumber2 = ref(""); //用户名
    const cardNumber3 = ref(""); //用户名
    const remarks1 = ref(""); //用户名
    const remarks2 = ref(""); //用户名
    const remarks3 = ref(""); //用户名
    const errorMsg = ref(); //错误提示
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const wxEnable = ref("");
    //由于这里的回车键触发事件和商品搜索框的回车键触发事件冲突，引入keyEnter变量判断
    document.onkeydown = function () {
      var key = window.event.keyCode;
      if (key == 13 && keyEnter.value) {
        login();
      }
    };
    //通过replace方式跳转页面
    const goToPage = (path) => {
      //带一个标识回去告诉businessInfo页面是从编辑页面跳转过来的
      if(stateCode.value == 3){
        router.push({
        path,
        query:{
          flag:1
        }
      })
    }else(
      ElMessage.warning('待审核和审核通过的不可以进行编辑')
    )
    };
     //三级地区接口数据
    const province = reactive({data:[]})    //省级数据
    const city = reactive({data:[1]})        //城市列表数据
    const areas = reactive({data:[1]})        //地区信息
    const provincevalue = ref('')           //选中省份信息
    const cityvalue = ref('')                //选中城市信息
    const areavalue = ref('')                //选中地区信息
    //获取城市信息函数
    // const choosecity = (val) => {
    //   // console.log(val)
      // provincevalue.value = val
    //   const file = province.data.find(item => item.regionName == val)
    //   const data = area.filter(item => item.regionCode == file.regionCode)//regionCode 地区编码
    //   console.log(data)
    //   city.data = data[0].children
      // console.log(city.data)
    // }
    //获取地区信息
    const choosearea = (val) => {
      cityvalue.value = val
      const data = city.data.filter(item => item.regionName == val)
      areas.data = data[0].children
    }

    //经营执照信息
    const cardimg = ref('') //工商执照照片
    const cards = ref('')   //工商执照证号
    const cardtime = ref('')  //工商执照有效期
    const cardremarks = ref('')  //工商备注信息
    const personimg = ref('') //法人执照照片
    const persons = ref('')   //法人执照证号
    const persontime = ref('')  //法人执person
    const personremarks = ref('')  //法人备注信息
    const medcineimg = ref('') //药品执照照片
    const medcines = ref('')   //药品执照证号
    const medcinetime = ref('')  //药品执照有效期
    const medcineremarks = ref('')  //药品备注信息
    const stateValues = ref('')   //审核状态
    const stateValues_type = ref('')   //审核状态
    const refuseReasons = ref('')  //审核失败原因
    const arrlist = reactive({data:[]}) //审核项信息
    const stateCode = ref('')   //审核状态码

    //还没提交经营信息跳转函数
    const gotoApplyInfo = (type) => {
      if(type!=-1){
        router.push('/auditStatus')
      }else{
        router.push('/businessInfo')
      }
    }

    const goToPagez = ()=>{
      disabledFlag.value = false
    }

    const pushPic_close = (index)=>{
      arrlist.data[index].image = ''
    }

    // 新
    const disabledFlag = ref(true);
    const memberList = reactive({
      enterpriseName: '', //单位名称
      // enterpriseAddress: "", //单位地址
      // invoiceTitle:'',//发票抬头
      // taxCode: '', //纳税人识别号
      // registerAddr: '', //注册地址
      // registerPhone: '', //注册电话
      // bankName: '', //开户银行
      // bankAccount: '', //银行账户
      // receiverName: '', //收票人姓名
      // receiverMobile: '', //收票人电话
      // receiverAddress: '', //收票人地址
    })
    const isApply = ref('') //是否提交过经营信息标志
    const receiveInvoiceAddressId = ref('');//发票收获人地址id
    const memberEnterpriseId = ref('');//会员资质信息id
    const invoiceId = ref('');//发票id
    const dynamic = reactive({//开票信息
      data:{},
    })
    const enterprise = reactive({
      data:[]
    })


    onMounted(async() => {
      manageList()
    });

    //上传图片
    const changepic = (e) => {

     //上传头像
     let newsrc = new FileReader(); //读取文件
     let data = new FormData();
     const index = e.target.dataset.index;
     let ind = e.target.dataset.ind;
     data.append("file", e.target.files[0]); //删除了name
     data.append("source", "evaluate");
     let config = {
       headers: { "Content-Type": "multipart/form-data" },
     };
     let url = apiUrl + "/v3/oss/common/upload";
     if (e.target.files[0].size < Math.pow(1024, 2) * 30) {
       tempSrc.value = newsrc;
       axios.post(url, data, config).then((res) => {
         if (res.status === 200) {
           ElMessage.success("上传图片成功");
           // tempSrc.value = res.data.data.url;
           // avatar.value = res.data.data.path;
           if(ind!=-1){
             dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = res.data.data.path
             dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = res.data.data.url
             dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].thisModification = res.data.data.thisModification
           }else{
             dynamic.data.enterpriseQualificationListVOS[index].value1 = res.data.data.path
             dynamic.data.enterpriseQualificationListVOS[index].value2 = res.data.data.url
             dynamic.data.enterpriseQualificationListVOS[index].thisModification = res.data.data.thisModification
           }
         } else {
           ElMessage.warning("上传图片失败");
         }
       });
     } else {
       ElMessage.warning("图像应小于30MB");
       tempSrc.value = avatar.value;
     }
   };


    // 编辑
    const manageList = ()=>{
      proxy
      .$post("/v3/member/front/memberEnterPrise/apply/detail")
          .then((res) => {
            if(res.data.enterpriseAuditState==null){
              isApply.value = 0
              return
            }else if(res.data.enterpriseAuditState!=2){
              isApply.value = 1
              return
            }else{
              isApply.value = 2
            }
            let TypeId  = res.data.enterpriseTypeId
            let obj = {}
            enterprise.data.forEach(item=>{
              if(item.enterpriseTypeId==TypeId){
                obj = item
              }
            })
            dynamic.data.enterpriseTypeId = res.data.enterpriseTypeId
            dynamic.data.enterpriseTypeName = res.data.enterpriseTypeName
            res.data.memberQualificationListDynamic.forEach(item=>{
              if (item.formatType == 3) {
                  if(item.value1==2){
                    item.flag = true
                  }else{
                    item.flag = false
                  }
              }
              item.memberSupplyDTOList.forEach(it=>{
                if (it.formatType == 3) {
                    if(it.value1==2){
                      it.flag = true
                    }else{
                      it.flag = false
                    }
                }
              })
            })
            stateValues.value = res.data.enterpriseQualificationStateValue
            stateValues_type.value = res.data.enterpriseQualificationState
            refuseReasons.value = res.data.refuseReason==null?'':res.data.refuseReason
            obj.enterpriseQualificationListVOS = res.data.memberQualificationListDynamic
            dynamic.data.enterpriseQualificationListVOS = obj.enterpriseQualificationListVOS
            memberList.enterpriseName=res.data.enterpriseName //单位名称
            if(res.data.memberReceiveAddressOa){
              memberListInfo.receiveName = res.data.memberReceiveAddressOa.receiveName
              memberListInfo.contactMobile = res.data.memberReceiveAddressOa.receiveTel
              memberListInfo.addressDetail = res.data.memberReceiveAddressOa.detailAddress
              let areas = []
              areas.push(
                res.data.memberReceiveAddressOa.provinceCode,
                res.data.memberReceiveAddressOa.cityCode,
                res.data.memberReceiveAddressOa.districtCode
              )
              memberListInfo.area = areas
              memberListInfo_address.value = res.data.memberReceiveAddressOa.addressAll

            }
            // memberList.enterpriseAddress=res.data.enterpriseAddress //单位地址
            // memberList.taxCode=res.data.memberInvoiceVO.taxCode //纳税人识别号
            // memberList.registerAddr=res.data.memberInvoiceVO.registerAddr //注册地址
            // memberList.registerPhone=res.data.memberInvoiceVO.registerPhone //注册电话
            // memberList.bankName=res.data.memberInvoiceVO.bankName //开户银行
            // memberList.bankAccount=res.data.memberInvoiceVO.bankAccount //银行账户
            // memberList.receiverName=res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiverName //收票人姓名
            // memberList.receiverMobile=res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiverMobile //收票人电话
            // memberList.receiverAddress=res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiverAddress //收票人地址
            // memberList.invoiceTitle=res.data.memberInvoiceVO.invoiceTitle //发票抬头
            memberEnterpriseId.value = res.data.memberEnterpriseId
            // invoiceId.value = res.data.memberInvoiceVO.invoiceId
            // receiveInvoiceAddressId.value = res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiveInvoiceAddressId
            // proxy
            // .$post(url, memberList, "json")
            // .then((res) => {
            //   if (res.state == 200) {
            //     ElMessage.warning('提交成功')

            //   }
            // });
          });
    }

    // 选择长期
    const aptit_radio = (e,index,ind)=>{
      if(ind!=-1){
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = 2
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].flag = e
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = ''
        }else{
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].flag = e
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = 1
        }
      }else{
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].value1 = 2
          dynamic.data.enterpriseQualificationListVOS[index].value2 = ''
          dynamic.data.enterpriseQualificationListVOS[index].flag = e
        }else{
          dynamic.data.enterpriseQualificationListVOS[index].flag = e
          dynamic.data.enterpriseQualificationListVOS[index].value1 = 1
        }
      }
    }

    //选择日期
    const cardInp = (e,index,ind) => {
      if(ind!=-1){
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = 1
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].flag = false
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = e
        }
      }else{
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].value1 = 1
          dynamic.data.enterpriseQualificationListVOS[index].flag = false
          dynamic.data.enterpriseQualificationListVOS[index].value2 = e
        }
      }
    };

     // 删除图片
     const close_img = (index,ind)=>{
      if (ind == -1) {
        dynamic.data.enterpriseQualificationListVOS[index].value2 = ''
        dynamic.data.enterpriseQualificationListVOS[index].value1 = ''
      } else {
        dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = ''
        dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = ''
      }
    }
    const gologin_flag = ref(false);
    // 提交
    const submit = ()=>{
        var reg = /\s+/g;
        // 单位名称
        if (memberList.enterpriseName == '' || memberList.enterpriseName.replace(reg,'') == '') {
          ElMessage.warning("请填写企业全称！")
          return
        }
        // if (memberListInfo.receiveName == '' || memberListInfo.receiveName.trim() == '') {
        //   ElMessage.warning("请填写收货人姓名！")
        //   return
        // }
        // if (memberListInfo.contactMobile == '' || memberListInfo.contactMobile.trim() == '') {
        //   ElMessage.warning("请填写收货电话！")
        //   return
        // }
        // if(checkMobile(memberListInfo.contactMobile)!=true){
        //   ElMessage.warning("请填写正确的收货电话！")
        //   return
        // }
        if (memberListInfo.area.length==0) {
          ElMessage.warning("请选择所在地区！")
          return
        }
        if (memberListInfo.addressDetail == '' || memberListInfo.addressDetail.trim() == '') {
          ElMessage.warning("请填写详细地址！")
          return
        }
        // // 单位地址
        // if (memberList.enterpriseAddress == '' || memberList.enterpriseAddress.trim() == '') {
        //   ElMessage.warning("请填写单位地址！")
        //   return
        // }
        // // 发票抬头
        // if (memberList.invoiceTitle == '' || memberList.invoiceTitle.trim() == '') {
        //   ElMessage.warning("请填写发票抬头！")
        //   return
        // }
        // // 纳税人识别号
        // if (memberList.taxCode == '' || memberList.taxCode.trim() == '') {
        //   ElMessage.warning("请填写纳税人识别号！")
        //   return
        // }
        // // 注册地址
        // if (memberList.registerAddr == '' || memberList.registerAddr.trim() == '') {
        //   ElMessage.warning("请填写注册地址！")
        //   return
        // }
        // // 注册电话
        // if (memberList.registerPhone == '' || memberList.registerPhone.trim() == '') {
        //   ElMessage.warning("请填写注册电话！")
        //   return
        // }
        // // 开户银行
        // if (memberList.bankName == '' || memberList.bankName.trim() == '') {
        //   ElMessage.warning("请填写开户银行！")
        //   return
        // }
        // // 银行账户
        // if (memberList.bankAccount == '' || memberList.bankAccount.trim() == '') {
        //   ElMessage.warning("请填写银行账户！")
        //   return
        // }

        for (let i = 0; i < dynamic.data.enterpriseQualificationListVOS.length; i++) {
          let item = dynamic.data.enterpriseQualificationListVOS[i]
          if (item.isRequired == 1) {
            if (item.formatType == 2 && item.value1.trim().length == 0) {
              ElMessage.warning('请填写' + item.enterpriseQualificationName)
              return
            }
            if (item.formatType == 3 && item.value1 == 1) {
              if (item.value2.length == 0) {
                ElMessage.warning('请选择' + item.enterpriseQualificationName)
                return
              }
            }
            if (item.formatType == 1 && item.value1.length == 0) {
              ElMessage.warning('请上传' + item.enterpriseQualificationName)
              return
            }
          }
          for (let j = 0; j < item.memberSupplyDTOList.length; j++) {
            let it = item.memberSupplyDTOList[j]
            if (it.isRequired == 1) {
              if (it.formatType == 2 && it.value1.trim().length == 0) {
                ElMessage.warning('请填写' + it.enterpriseQualificationName)
                return
              }
              if (it.formatType == 3 && it.value1 == 1) {
                if (!it.value2) {
                  ElMessage.warning('请选择' + it.enterpriseQualificationName)
                  return

                }
              }
              if (it.formatType == 1 && it.value1.length == 0) {
                ElMessage.warning('请上传' + it.enterpriseQualificationName)
                return
              }
            }
          }
        }
         memberList.enterpriseName = memberList.enterpriseName.replace(reg,'')
        let params = {}
        params = memberList
        params.memberQualificationListDynamicAddDTOS = dynamic.data.enterpriseQualificationListVOS
        params.enterpriseTypeId = dynamic.data.enterpriseTypeId
        params.enterpriseTypeName = dynamic.data.enterpriseTypeName
        let url='/v3/member/front/memberEnterPrise/apply/update'
        params.memberReceiveAddressOa = {}
        params.memberReceiveAddressOa.addressAll =  cascaderAddr.value.getCheckedNodes()[0].pathLabels.join("");//组合
        params.memberReceiveAddressOa.provinceCode = memberListInfo.area[0]//省
        params.memberReceiveAddressOa.cityCode = memberListInfo.area[1]//市
        params.memberReceiveAddressOa.districtCode = memberListInfo.area[2]//区
        params.memberReceiveAddressOa.receiveName = memberListInfo.receiveName //收货人
        params.memberReceiveAddressOa.receiveTel = memberListInfo.contactMobile//收货电话
        params.memberReceiveAddressOa.detailAddress = memberListInfo.addressDetail//详细地址
        // params.receiveInvoiceAddressId = receiveInvoiceAddressId.value
        params.memberEnterpriseId = memberEnterpriseId.value
        // params.invoiceId = invoiceId.value
        if(gologin_flag.value){
          return
        }
        gologin_flag.value=true
        proxy
          .$post(url, params, "json")
          .then((res) => {
            if (res.state == 200) {
              disabledFlag.value = true
              gologin_flag.value = false
              manageList()
            }else{
              gologin_flag.value = false
              ElMessage.warning(res.msg)
            }
          });
        // 直接重定向到成功页面
        ElMessageBox.confirm('经营信息已提交，请耐心等待审核结果，详情可咨询商城客服', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'success',
        }).then(
            router.replace("/member/index")
        )
    }



    return {
      choosearea,
      // choosecity,
      province,
      L,
      changepic,
      tempSrc,
      rander,
      rander2,
      rander3,
      company,
      receive,
      contactphone,
      clearInputVal,
      addressDetails,
      cardNumber1,
      cardNumber2,
      cardNumber3,
      remarks1,
      remarks2,
      remarks3,
      goToPage,
      errorMsg,
      wxEnable,
      pushPic_close,
      tirggerFile,
      tirggerFile2,
      tirggerFile3,
      goToPagez,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1,
      value2,
      value3,
      provincevalue,
      city,
      cityvalue,
      areavalue,
      areas,
      changepic1,
      changepic2,
      imgSrc,
      img3Src,
      cardimg,
      cards,
      cardtime,
      cardremarks,
      personimg,
      persons,
      persontime,
      personremarks,
      medcineimg,
      medcines,
      medcinetime,
      medcineremarks,
      stateValues,
      refuseReasons,
      arrlist,
      stateCode,
      isApply,
      gotoApplyInfo,
      disabledFlag,

      enterprise,//企业类型
      aptit_radio,//长期
      dynamic,//开票信息
      manageList,//编辑
      memberList,//数据
      close_img,//删除图片
      receiveInvoiceAddressId,//发票收获人地址id
      memberEnterpriseId,//会员资质信息id
      invoiceId,//	发票id
      cardInp,
      submit,
      stateValues_type,
      memberListInfo,
      areaDataArray,
      cascaderProps,
      cascaderAddr,
      memberListInfo_address,
      go_tem,
      gologin_flag,

      // select: { province: "广东省", city: "广州市", area: "海珠区" },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '../../style/businesslist.scss';
.tem_cla {
    color: rgba(23, 123, 255, 1);
font-size: 18px;
text-align: left;
font-family: SourceHanSansSC-regular;
cursor: pointer;
}

.top .item {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;

  &:after {
    display: none !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.bindMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .msg_title {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 26px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  .msg_content {
    margin-top: 30px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .msg_tip {
    margin-top: 12px;
    line-height: 20px;
  }

  .go_bind_on {
    margin-top: 30px;
    width: 120px;
    height: 36px;
    background: #fc1c1c;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }

  .go_bind_update {
    margin-left: 20px;
    margin-top: 30px;
    width: 120px;
    height: 36px;
    background: #999;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 186px;
  height: 44px;
  margin-right: 17px;
  color: #101010;
  font-size: 14px;
  font-family: Microsoft Yahei;
  // border: 1px solid #bbbbbb;
  .el-icon-arrow-up:before {
    color: #bbbbbb;
    font-weight: 600;
  }
}
::v-deep.el-breadcrumb{
  height: 50px;
}
.sld_Business .sld_Business_bodyer .Business_form{
  width: 1010px;
  float: right;
  height: 100%;
  margin-left: 15px;
}
.submit{
  cursor: pointer;
}
.article{
  position: relative;
  .apply_btn{
    width: 160px;
    height: 50px;
    border: 0;
    background-color: #fc1c1c;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    margin: 30px auto 200px; /* 上下边距为30px和200px，左右自动居中 */
    display: block; /* 将按钮转换为块级元素以使其居中 */
    cursor: pointer;
  }
  .noapply_info{
    display: inline-block;
    font-size: 20px;
    padding: 20px;
    padding-top: 160px;
    margin-left: 280px;
  }
}
.checkState{
  position: absolute;
  top: 30px;
  right: 35px;
  display:flex;
  flex-direction: column;
  span{
    color: #F70404;
  }
}
.submit_qualification{
  display: flex;
  width: 840px;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-top: 20px;
  p{
    font-size: 18px;
  }
  .qualification{
    color: #F91F1F;
    cursor: pointer;
  }
}
.region:last-child {
      border-bottom: none;
    }

</style>
<style lang="scss">
.sld_Business{
  .el-cascader{
    width: 355px !important;
    .el-input{
      width: 100% !important;
    }
    ::placeholder {
      color: #757575 !important;// 如果有时不生效，可能是权限不够，加一个!important即可
    }
    .el-input__inner{
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: 10px !important;
      color: #000;
    }
  }
}

</style>
