<template>
  <div class="sld_Business">
    <div class="sld_Business_header">
    <NavTopBar />

    </div>
    <div class="sld_Business_bodyer">
        <div class="result" v-if="result==1">
            <div>{{L['公司信息已提交，请等待审核']}}</div>
<!--            <div class="goto_page" @click="goToPage(1)">重新编辑</div>-->
        </div>
        <div class="result" v-if="result == 3">
            <div>{{L['公司信息审核拒绝']}}</div>
            <div>{{'拒绝原因：' + resultReason}}</div>
            <div class="goto_page" data-index="2" @click="goToPage(1)">{{L['重新提交']}}</div>
        </div>
        <div class="result" v-if="result==2">
            <div>{{L['公司信息审核通过']}}</div>
            <div></div>
            <div class="goto_page" @click="goToPage(2)">{{L['点击进入商城']}}</div>
        </div>
    </div>
<!--    <div class="sld_Business_footer">-->
<!--    </div>-->
  </div>
</template>
<script>
import NavTopBar from "../../../components/NavTopBar.vue";

import { useRouter, onBeforeRouteLeave, useRoute } from "vue-router";
import { ref, getCurrentInstance, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { VDistpicker } from "v-distpicker";

export default {
  components: { VDistpicker, NavTopBar },
  name: "LoginIndex",
  setup() {
    // const province = ref("");
    // const city = ref("");
    // const area = ref("");
    const result = ref(''); //审核状态 0-请等待 1-审核通过 2-审核不通过
    const value1 = ref("");
    const value2 = ref("");
    const value3 = ref("");
    const resultReason = ref('')    //拒绝原因
    const tirggerFile = (event) => {
      var file = event.target.files;
      var reader = new FileReader(); //读取文件
      reader.readAsDataURL(file[0]);
      reader.onload = function () {
        document.getElementById("img1").src = reader.result;
      };
    };
    const store = useStore();
    const keyEnter = ref(true);
    const name = ref(""); //用户名
    const errorMsg = ref(); //错误提示
    const password = ref(""); //密码
    const loginType = ref(1); //登陆类型：1-账号密码登陆，2-手机验证码登陆
    const router = useRouter();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const countDownM = ref(0); //短信验证码倒计时
    const timeOutId = ref(""); //定时器的返回值
    const configInfo = ref(store.state.configInfo);
    const bindedVisible = ref(false);
    const defaultImg = ref(
      'this.src="' + require("../../../assets/common_top_logo.png") + '"'
    );
    const defaultBgImg = ref(
      'this.src="' + require("../../../assets/login_bg.png") + '"'
    );
    const fromurl = ref("");
    const wxEnable = ref("");
    const pcAppid = ref("");
    //由于这里的回车键触发事件和商品搜索框的回车键触发事件冲突，引入keyEnter变量判断
    document.onkeydown = function () {
      var key = window.event.keyCode;
      if (key == 13 && keyEnter.value) {
        login();
      }
    };
    const binded = ref("");
    const bindType = ref(1);
    const login = () => {
      let param = {};
      param.mobile = name.value;
      param.bindType = bindType.value;
      param.smsCode = password.value;
      param.resource = 1;
      param.bindKey = route.query.code;
      //手机号验证
      if (!param.mobile) {
        errorMsg.value = L[" 手机号"];
        return false;
      } else {
        let checkMobile = proxy.$checkPhone(param.mobile);
        if (checkMobile !== true) {
          errorMsg.value = checkMobile;
          return false;
        }
      }

      //验证码校验
      if (!param.smsCode) {
        errorMsg.value = L[" 短信验证码"];
        return false;
      } else {
        let checkSmsCode = proxy.$checkSmsCode(param.smsCode);
        if (checkSmsCode !== true) {
          errorMsg.value = checkSmsCode;
          return false;
        }
      }
      //如果未登录vuex中有购物车数据，需同步到该用户
      let cartInfo = [];
      let cartListData = store.state.cartListData;
      if (JSON.stringify(cartListData) != "{}") {
        cartListData.storeCartGroupList.map((cartListItem) => {
          cartListItem.promotionCartGroupList.map((promotItem) => {
            promotItem.cartList.map((cartItem) => {
              cartInfo.push({
                productId: cartItem.productId,
                buyNum: cartItem.buyNum,
              });
            });
          });
        });
        param.cartInfo = JSON.stringify(cartInfo);
      }
      proxy
        .$post("v3/member/front/login/wechat/bindMobile", param)
        .then((res) => {
          if (res.state == 200) {
            //将用户信息存缓存，并跳转到首页
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
            //获取用户信息，并同步信息到vuex
            proxy.$get("/v3/member/front/member/getInfo").then((res) => {
              if (res.state == 200) {
                store.commit("updateMemberInfo", res.data);
                proxy.$getLoginCartListData(); //更新购物车数据
                ElMessage.success(res.msg);
                setTimeout(() => {
                  router.replace({ path: "/member/index" });
                }, 1000);
              }
            });
          } else if (res.state == 267) {
            binded.value = res.data;
            bindedVisible.value = true;
          } else {
            //提示错误
            errorMsg.value = res.msg;
          }
        });
    };
    //清空输入框内容
    const clearInputVal = (type) => {
      if (type == "name") {
        name.value = "";
      } else if (type == "password") {
        password.value = "";
      }
    };

    watch([name, password], () => {
      let checkSmsCode = proxy.$checkSmsCode(password.value);
      let checkMobile = proxy.$checkMobile(name.value);
      name.value = name.value.toString().substring(0, 11);
      password.value = password.value.toString().substring(0, 6);
      if (name.value || password.value) {
        errorMsg.value = "";
      }
    });

    const confirmBind = (type) => {
      bindType.value = type;
      login();
    };

    //获取短信验证码
    const getSmsCode = () => {
      if (countDownM.value) {
        return false;
      }
      let checkMobile = proxy.$checkPhone(name.value);
      let checkCode = proxy.$checkSmsCode(password.value);
      if (checkMobile !== true) {
        errorMsg.value = checkMobile;
      } else {
        let param = {};
        param.mobile = name.value;
        param.type = "wxAuth";
        proxy.$get("v3/msg/front/commons/smsCode", param).then((res) => {
          if (res.state == 200) {
            countDownM.value = 60;
            countDown();
          } else {
            //提示错误
            errorMsg.value = res.msg;
          }
        });
      }
    };
    //倒计时
    const countDown = () => {
      countDownM.value--;
      if (countDownM.value == 0) {
        clearTimeout(timeOutId.value);
      } else {
        timeOutId.value = setTimeout(countDown, 1000);
      }
    };
    //通过replace方式跳转页面
    const goToPage = (type) => {
      if(type == 1){
        console.log(type)
      //  router.replace('/businessInfo?flag=' + '1')
      router.replace({
        path:'/businessInfo',
        query:{
          flag:'1',
          type:'1'
        }
      })
      }
      if(type == 2){
        router.replace({
        path: 'index',
      });
      }
    };

    onMounted(() => {
      proxy.$post('/v3/member/front/memberEnterPrise/apply/detail').then(ret => {
        result.value = ret.data.enterpriseQualificationState
        resultReason.value = ret.data.refuseReason
      })
    });

    return {
      L,
      loginType,
      login,
      name,
      password,
      clearInputVal,
      errorMsg,
      getSmsCode,
      countDownM,
      goToPage,
      configInfo,
      defaultImg,
      defaultBgImg,
      wxEnable,
      bindedVisible,
      binded,
      confirmBind,
      tirggerFile,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1,
      value2,
      value3,

      select: { province: "广东省", city: "广州市", area: "海珠区" },
      result,
      resultReason
    };
  },
   methods: {

      }
};
</script>
<style lang="scss" scoped>
@import "../../../style/auditStatus.scss";

.top .item {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;

  &:after {
    display: none !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.bindMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .msg_title {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 26px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  .msg_content {
    margin-top: 30px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .msg_tip {
    margin-top: 12px;
    line-height: 20px;
  }

  .go_bind_on {
    margin-top: 30px;
    width: 120px;
    height: 36px;
    background: #fc1c1c;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }

  .go_bind_update {
    margin-left: 20px;
    margin-top: 30px;
    width: 120px;
    height: 36px;
    background: #999;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
}
.goto_page{
  cursor: pointer;
  width: 120px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(255, 21, 8, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  margin: 0 auto;
  margin-top: 63px;
}
</style>
