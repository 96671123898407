<template>
  <div class="sld_Business_o">
    <NavTopBar />
    <div class="sld_Business_bodyer">
      <div class="Business_form">
        <div class="title">企业类型</div>
        <div class="article">
          <div class="region">
            <div class="line">
              <div style="padding-top: 20px;">
                <span style="color: red">*</span>
                企业类型：
              </div>
              <div>
                <el-radio-group v-model="companyType" class="ml-4" @change="company_type" style="padding-top: 0;" v-if="enterprise.data.length>0">
                  <el-radio :label="item.enterpriseTypeId" size="large" v-for="(item,index) in enterprise.data" :key="index">{{item.enterpriseTypeName}}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="title">{{ L["经营信息"] }}</div> -->
        <div class="article">
          <div class="region">
            <div class="line">
              <div>
                <span style="color: red">*</span>
                企业全称：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入企业全称"
                  v-model="memberList.enterpriseName"
                />
              </div>
            </div>
            <!-- <div class="line">
              <div>
                <span>*</span>
                单位地址：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入单位地址"
                  v-model="memberList.enterpriseAddress"
                />
              </div>
            </div> -->
          </div>
          <div class="title">收货信息（和收货委托书上一致）</div>
          <div class="article">
            <div class="region">
               <div class="line">
              <div>
                <span style="color: red">*</span>
                {{ L["收货人："] }}
              </div>
              <div>
                <input
                class="region_inp"
                  type="text"
                  maxlength="20"
                  placeholder="请输入收货人姓名"
                  v-model="memberListInfo.receiveName"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span style="color: red">*</span>
                收货电话：
              </div>
              <div>
                <input
                class="region_inp"
                  type="text"
                  maxlength="20"
                  placeholder="请输入收货电话"
                  v-model="memberListInfo.contactMobile"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span style="color: red">*</span>
                所在地区：
              </div>
              <div>
                <el-cascader class="region_inp" ref="cascaderAddr" v-model="memberListInfo.area" :options="areaDataArray" placeholder="请选择所在地区"
            :props="cascaderProps" readonly="true"></el-cascader>
              </div>
            </div>

            <div  v-if="dynamic.data.enterpriseTypeId=='13' || dynamic.data.enterpriseTypeId=='14' || dynamic.data.enterpriseTypeId=='15'">
                <div class="line">
                  <div>
                    <span style="color: red">*</span>
                    详细地址：
                  </div>
                  <div>
                    <input
                      class="region_inp"
                      type="text"
                      maxlength="40"
                      placeholder="请输入详细地址"
                      v-model="memberListInfo.addressDetail"
                    />
                  </div>
                </div>
            </div>
            <div  v-if="dynamic.data.enterpriseTypeId=='11' || dynamic.data.enterpriseTypeId=='12' ">
                <div class="line">
                  <div>
                    <span>*</span>
                    详细地址：
                  </div>
                  <div>
                    <input
                      class="region_inp"
                      style="background-color: #f0f0f0; /* 更亮的灰色背景 */ border: 1px solid #999; /* 灰色边框 */
                            color: #333; /* 深灰色文本颜色 */opacity: 1; /* 降低不可输入文本的透明度 */"
                      type="text"
                      maxlength="40"
                      placeholder="本栏默认为药品经营许可证上的仓库地址或注册地址，无需填写"
                      disabled
                      v-model="memberListInfo.addressDetail"
                    />
                  </div>
                </div>
            </div>

            </div>
        </div>
          <!-- <div class="title">开票信息</div> -->
        <!-- <div class="article">
          <div class="region">
            <div class="line">
              <div>
                <span>*</span>
                发票抬头：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入发票抬头"
                  v-model="memberList.invoiceTitle"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                纳税人识别号：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入纳税人识别号"
                  v-model="memberList.taxCode"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                注册地址：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入注册地址"
                  v-model="memberList.registerAddr"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                注册电话：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入注册电话"
                  v-model="memberList.registerPhone"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                开户银行：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入开户银行"
                  v-model="memberList.bankName"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                银行账户：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入银行账户"
                  v-model="memberList.bankAccount"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                收票人姓名：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入收票人姓名"
                  v-model="memberList.receiverName"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                收票人电话：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入收票人电话"
                  v-model="memberList.receiverMobile"
                />
              </div>
            </div>
            <div class="line">
              <div>
                <span>*</span>
                收票人地址：
              </div>
              <div>
                <input
                  class="region_inp"
                  type="text"
                  maxlength="40"
                  placeholder="请输入收票人地址"
                  v-model="memberList.receiverAddress"
                />
              </div>
            </div>
          </div>
        </div> -->
          <!-- 审核项开始 -->
          <div class="submit_qualification">
            <p>
              <span class="qualification" >请确保上传图片能够清晰辨识名称、地址、日期、证号等关键信息，以免因核对失败耽误您的宝贵时间。</span>
            </p>
          </div>
          <div
            class="region"
            v-for="(item, index) in dynamic.data.enterpriseQualificationListVOS"
            :key="index"
          >
            <div>
              <div class="line_pushPic" v-if="item.formatType==1">
                <div><span style="color: red" v-if="item.isRequired==1">*</span><span v-html="item.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
                <div class="line_pushPic_upload_o">
                  <div  class="line_pushPic_upload">
                    <img
                    id="img1"
                    v-if="item.value2"
                    :src="item.value2"
                    mode="scaleToFill"
                    />
                  </div>
                  <div>
                    <div class="add" v-if="!item.value2"></div>
                    <input
                    type="file"
                    ref="file2"
                    name="check_img_url"
                    :data-index="index"
                    :data-ind="-1"
                    @change="changepic($event)"
                    />
                    <!-- @change="changepic($event)" -->
                  </div>
                  <img src="@/assets/order/close.png" alt="" class="img_close" v-if="item.value2" @click="close_img(index,-1)">
                </div>
              </div>
              <div class="line" v-if="item.formatType==1&&item.template">
                <div>模版：</div>
                <div class="tem_cla" @click="go_tem(item.template)">案例模版</div>
              </div>


              <div class="line" v-if="item.formatType==2">
                <div><span style="color: red" v-if="item.isRequired==1">*</span><span v-html="item.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
                <div>
                  <input
                    type="text"
                    maxlength="30"
                    :placeholder="'请输入' + item.enterpriseQualificationName"
                    v-model="item.value1"
                  />
                </div>
              </div>
              <div class="line"  v-if="item.formatType==3">
                <div><span v-if="item.isRequired==1">*</span>{{ item.enterpriseQualificationName }}：</div>
                <div>
                  <el-config-provider :locale="locale">
                    <el-date-picker
                      v-model="item.value2"
                      type="date"
                      placeholder="选择日期"
                      style="width: 355px"
                      value-format="YYYY-MM-DD"
                      @change="cardInp($event,index,-1)"
                      :locale="locale"
                    >
                    </el-date-picker>
                  </el-config-provider>
                  <el-checkbox size="large" @change="aptit_radio($event,index,-1)" v-model="item.flag" :data-index="index" :checked="item.value1==2">长期</el-checkbox>
                </div>
              </div>
            </div>
            <div v-for="(it,ind) in item.memberSupplyDTOList" :key="ind">
              <div class="line_pushPic" v-if="it.formatType==1" style="margin-top: 5px;">
                <div><span style="color: red" v-if="it.isRequired==1">*</span><span v-html="it.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
                <div class="line_pushPic_upload_o">
                  <div class="line_pushPic_upload">
                    <img
                      id="img1"
                      v-if="it.value2"
                      :src="it.value2"
                      mode="scaleToFill"
                    />

                  </div>
                  <div>
                    <div class="add" v-if="!it.value2"></div>
                    <input
                      type="file"
                      ref="file2"
                      name="check_img_url"
                      :data-index="index"
                      :data-ind="ind"
                      @change="changepic($event)"
                    />
                    <!-- @change="changepic($event)" -->
                  </div>
                  <img src="@/assets/order/close.png" alt="" class="img_close" v-if="it.value2" @click="close_img(index,ind)">
                </div>
              </div>

              <div class="line" v-if="it.formatType==2">
                <div><span style="color: red" v-if="it.isRequired==1">*</span><span v-html="it.enterpriseQualificationName.replace('（', '<br>（')"></span>：</div>
                <div>
                  <input
                    type="text"
                    maxlength="30"
                    :placeholder="'请输入' + it.enterpriseQualificationName"
                    v-model="it.value1"
                    :data-index="index"
                    :data-ind="ind"
                  />
                </div>
              </div>
              <div class="line" v-if="it.formatType==3">
                <div><span v-if="it.isRequired==1">*</span>{{ it.enterpriseQualificationName }}：</div>
                <div>
                  <el-config-provider :locale="locale">
                    <el-date-picker
                      v-model="it.value2"
                      type="date"
                      placeholder="选择日期"
                      style="width: 355px"
                      value-format="YYYY-MM-DD"
                      @change="cardInp($event,index,ind)"
                      :locale="locale"
                    >
                    </el-date-picker>
                  </el-config-provider>
                  <el-checkbox :checked="it.value1==2?true:false" v-model="it.flag" size="large" @change="aptit_radio($event,index,ind)" :data-index="index">长期</el-checkbox>
                </div>
              </div>
            </div>
            <!-- <div class="line_high">
              <div>{{ L["备注："] }}</div>
              <div>
                <textarea
                  placeholder="请填写备注"
                  v-model="remarks1.data[index]"
                  @change="remarkInp"
                  :data-index="index"
                />
              </div>
            </div> -->
          </div>
          <!-- 审核项结束 -->
          <div
            class="submit"
            @click="goToPage('/auditStatus','code')"
            style="cursor: pointer; margin-bottom: 100px; border-radius: 10px;"
          >
            {{ L["点击提交"] }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="sld_Business">
    <div class="sld_Business_header">
    <NavTopBar />

    </div>
    <div class="sld_Business_bodyer">
        <div class="result" v-if="result==1">
            {{L['公司信息已提交，请等待审核']}}
        </div>
        <div class="result" v-if="result == 3">
            <div>{{L['公司信息审核拒绝']}}</div>
            <div>{{'拒绝原因：' + resultReason}}</div>
            <div class="goto_page" data-index="2" @click="goToPage(1)">{{L['重新提交']}}</div>
        </div>
        <div class="result" v-if="result==2">
            <div>{{L['公司信息审核通过']}}</div>
            <div></div>
            <div class="goto_page" @click="goToPage(2)">{{L['点击进入商城']}}</div>
        </div>
    </div>
    <div class="sld_Business_footer">
    </div>
  </div> -->
  <el-dialog v-model="dialogVisible" :title="agreeContent.title"  center>
    <pre v-html="agreeContent.content" class="afterSales" style="white-space: pre-wrap;"></pre>
    <template #footer>
      <span class="dialog-footer">
        <div class="dialogVisible_btn" @click="goToPage('/auditStatus','go')" v-if="!gologin_flag">
          同意
        </div>
        <div class="dialogVisible_btn1"  v-else>
          同意
        </div>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import {ElMessage, ElMessageBox} from "element-plus";
import areaData from "../../../assets/area.json";
import { ElConfigProvider } from "element-plus"; //设置element中文显示
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import NavTopBar from "../../../components/NavTopBar.vue";
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import { VDistpicker } from "v-distpicker";
import { apiUrl } from "../../../utils/config";
const cascaderAddr = ref(null);
import axios from "axios";
// import area from "../../../assets/area.json";
import { useRoute, useRouter } from "vue-router";
import { checkMobile, checkPhone } from "../../../utils/common"; //导入表单验证函数
import { useStore } from "vuex";
export default {
  name: "LoginIndex",
  components: {
    VDistpicker,
    NavTopBar,
    [ElConfigProvider.name]: ElConfigProvider,
  },

  setup() {
      const store = useStore()
      const router = useRouter();
      const route = useRoute();
      const areaDataArray = areaData;

      const dialogVisible = ref(false);
      const memberInfo = ref(store.state.memberInfo);
    let locale = zhCn; //设置中文
    const tempSrc = reactive({ data: [] });
    const avatar = ref(""); //用户头像
    const value1 = reactive({ data: [] }); //工商执照有效期
    const value2 = ref(""); //药品营业许可证有效期
    const value3 = ref(""); //法人委托书有效期
    const provinceCode = ref('');
    const cityCode = ref('');
    const districtCode = ref('');
    const manageInfoId = reactive({ data: [] });

    const tirggerFile = (event) => {
      var file = event.target.files;
      var reader = new FileReader(); //读取文件
      reader.readAsDataURL(file[0]);
      reader.onload = function () {
        document.getElementById("img1").src = reader.result;
      };
    };

    const keyEnter = ref(true);
    const rander = ref(""); //用户名
    const rander2 = ref(""); //用户名
    const rander3 = ref(""); //用户名

    const enterpriseName = ref(""); //用户名
    const receiveName = ref(""); //用户名
    const contactMobile = ref(""); //用户名
    const clearInputVal = ref(""); //用户名
    // const addressDetail = ref(""); //详细地址
    const cardNumber1 = reactive({ data: [] }); //用户名
    const cardNumber2 = ref(""); //用户名
    const cardNumber3 = ref(""); //用户名
    const remarks1 = reactive({ data: [] }); //用户名
    const remarks2 = ref(""); //用户名
    const remarks3 = ref(""); //用户名
    const long_term = reactive({data:[]});//长期非长期
    const errorMsg = ref(); //错误提示
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const agreeContent = reactive({
      content:'',
      title:''
    })
    const wxEnable = ref("");
    //由于这里的回车键触发事件和商品搜索框的回车键触发事件冲突，引入keyEnter变量判断
    // document.onkeydown = function () {
    //   var key = window.event.keyCode;
    //   if (key == 13 && keyEnter.value) {
    //     login();
    //   }
    // };

    //手机号验证
    // const checknumber = () => {
    //   const ret = checkPhone(contactMobile.value);
    //   console.log(ret);
    //   if (ret == true) return;
    //   ElMessage.warning(ret);
    // };

    //三级地区接口数据
    const province = reactive({ data: [] }); //省级数据
    const city = reactive({ data: [1] }); //城市列表数据
    const areas = reactive({ data: [1] }); //地区信息
    const provincevalue = ref(""); //选中省份信息
    const cityvalue = ref(""); //选中城市信息
    const areavalue = ref(""); //选中地区信息
    const certificateCards = reactive({ data: [] }); //审核项信息
    //获取城市信息函数
    const choosecity = (val) => {
      provincevalue.value = val;
      cityvalue.value = ''//file.children[0].regionName
      areavalue.value = ''
      const file = province.data.find((item) => item.regionName == val);
      provinceCode.value = file.regionCode
      city.data = file.children;
    };
    //获取地区信息
    const choosearea = (val) => {
      cityvalue.value = val;
      const data = city.data.filter((item) => item.regionName == val);
      areas.data = data[0].children;
      cityCode.value = data[0].regionCode
      areavalue.value = ''
    };
    const choosarea = (val)=>{
      areavalue.value = val
      const data = areas.data.filter((item) => item.regionName == val);
      districtCode.value = data[0].regionCode
    }

    //获取备注
    const remarkInp = (e) => {
    };

    //经营执照信息
    const addressDetail = ref(""); //收获地址
    const memberid = ref(""); //成员id


    // 新
    onMounted(async () => {

      getCompany()

    });


    const companyType = ref('1');
    const memberList = reactive({
      enterpriseName: '', //单位名称

      // enterpriseAddress: "", //单位地址
      // invoiceTitle:'',//发票抬头
      // taxCode: '', //纳税人识别号
      // registerAddr: '', //注册地址
      // registerPhone: '', //注册电话
      // bankName: '', //开户银行
      // bankAccount: '', //银行账户
      // receiverName: '', //收票人姓名
      // receiverMobile: '', //收票人电话
      // receiverAddress: '', //收票人地址
    })
    const memberListInfo = reactive({
      receiveName:'',//收货人
      contactMobile: '', //收货电话
      addressDetail: '', //详细地址
      area:[],
    })
    const cascaderProps = { label: "regionName", value: "regionCode" };
    const receiveInvoiceAddressId = ref('');//发票收获人地址id
    const memberEnterpriseId = ref('');//会员资质信息id
    const invoiceId = ref('');//发票id
    const dynamic = reactive({//开票信息
      data:{},
    })
    const enterprise = reactive({
      data:[]
    })


    // 获取企业类型
    const getCompany = ()=>{
      proxy
      .$post("/v3/member/front/memberEnterPrise/apply/list")
          .then((res) => {
            res.data.forEach(item => {
              item.enterpriseQualificationListVOS.forEach(it => {
                if (it.formatType == 3) {
                  it.value1 = 1
                  it.flag = false
                } else {
                  it.value1 = ''
                }
                it.value2 = ''
                it.memberSupplyDTOList = it.supplyDTOList
                delete it.supplyDTOList
                it.memberSupplyDTOList.forEach(i => {
                  if (i.formatType == 3) {
                    i.value1 = 1
                    i.flag = false
                  } else {
                    i.value1 = ''
                  }
                  i.value2 = ''
                  // if(i.formatType==1){
                  // }
                })
              })
            })
            let index = res.data.findIndex(item=>{
              return item.enterpriseTypeName == '测试'
            })
            if(index!=-1){
              res.data.splice(index,1)
            }
            enterprise.data = res.data
            if(route.query.type==1){
              manageList()
            }else{
              companyType.value = res.data[0].enterpriseTypeId
              getInvoicing(companyType.value)
            }
          });
    }
    // 编辑
    const manageList = ()=>{
      proxy
      .$post("/v3/member/front/memberEnterPrise/apply/detail")
          .then((res) => {
            console.log(res.data);
            companyType.value = res.data.enterpriseTypeId
            let obj = {}
            enterprise.data.forEach(item=>{
              if(item.enterpriseTypeId==companyType.value){
                obj = item
              }
            })
            dynamic.data.enterpriseTypeId = res.data.enterpriseTypeId
            dynamic.data.enterpriseTypeName = res.data.enterpriseTypeName
            res.data.memberQualificationListDynamic.forEach(item=>{
              if (item.formatType == 3) {
                  if(item.value1==2){
                    item.flag = true
                  }else{
                    item.flag = false
                  }
              }
              item.memberSupplyDTOList.forEach(it=>{
                if (it.formatType == 3) {
                    if(it.value1==2){
                      it.flag = true
                    }else{
                      it.flag = false
                    }
                }
              })
            })
            memberEnterpriseId.value = res.data.memberEnterpriseId
            obj.enterpriseQualificationListVOS = res.data.memberQualificationListDynamic
            dynamic.data.enterpriseQualificationListVOS = obj.enterpriseQualificationListVOS
            memberList.enterpriseName=res.data.enterpriseName //单位名称
            if(res.data.memberReceiveAddressOa){
              memberListInfo.receiveName = res.data.memberReceiveAddressOa.receiveName
              memberListInfo.contactMobile = res.data.memberReceiveAddressOa.receiveTel
              memberListInfo.addressDetail = res.data.memberReceiveAddressOa.detailAddress

            }
            let areas = []
            areas.push(
              res.data.memberReceiveAddressOa.provinceCode,
              res.data.memberReceiveAddressOa.cityCode,
              res.data.memberReceiveAddressOa.districtCode
            )
            memberListInfo.area = areas
            // memberList.enterpriseAddress=res.data.enterpriseAddress //单位地址
            // memberList.taxCode=res.data.memberInvoiceVO.taxCode //纳税人识别号
            // memberList.registerAddr=res.data.memberInvoiceVO.registerAddr //注册地址
            // memberList.registerPhone=res.data.memberInvoiceVO.registerPhone //注册电话
            // memberList.bankName=res.data.memberInvoiceVO.bankName //开户银行
            // memberList.bankAccount=res.data.memberInvoiceVO.bankAccount //银行账户
            // memberList.receiverName=res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiverName //收票人姓名
            // memberList.receiverMobile=res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiverMobile //收票人电话
            // memberList.receiverAddress=res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiverAddress //收票人地址
            // memberList.invoiceTitle=res.data.memberInvoiceVO.invoiceTitle //发票抬头

            if( res.data.memberInvoiceVO){
              invoiceId.value = res.data.memberInvoiceVO.invoiceId
              receiveInvoiceAddressId.value = res.data.memberInvoiceVO.receiveInvoiceAddressVOS[0].receiveInvoiceAddressId
            }
          });
    }
    // 根据企业类型获取开票信息
    const getInvoicing = (id)=>{
      let obj = {}
      enterprise.data.forEach(item=>{
        if(item.enterpriseTypeId==id){
          obj = item
        }
      })
      dynamic.data = obj
      console.log(dynamic.data);
    }

    // 企业类型
    const company_type = (e)=>{
      getInvoicing(e)
    }

    // 选择长期
    const aptit_radio = (e,index,ind)=>{
      if(ind!=-1){
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = 2
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].flag = e
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = ''
        }else{
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].flag = e
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = 1
        }
      }else{
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].value1 = 2
          dynamic.data.enterpriseQualificationListVOS[index].value2 = ''
          dynamic.data.enterpriseQualificationListVOS[index].flag = e
        }else{
          dynamic.data.enterpriseQualificationListVOS[index].flag = e
          dynamic.data.enterpriseQualificationListVOS[index].value1 = 1
        }
      }
    }

    //选择日期
    const cardInp = (e,index,ind) => {
      if(ind!=-1){
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = 1
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].flag = false
          dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = e
        }
      }else{
        if(e){
          dynamic.data.enterpriseQualificationListVOS[index].value1 = 1
          dynamic.data.enterpriseQualificationListVOS[index].flag = false
          dynamic.data.enterpriseQualificationListVOS[index].value2 = e
        }
      }
    };
    const go_tem = (url) => {
        window.open(url)
    }
    //上传图片
    const changepic = (e) => {
      const index = e.target.dataset.index;
      let ind = e.target.dataset.ind;
      // const manageInfoIds = e.target.dataset.manageinfoid;
      let newsrc = new FileReader(); //读取文件
      let data = new FormData();
      data.append("file", e.target.files[0]); //删了name
      data.append("source", "headImg");
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      let url = apiUrl + "/v3/oss/common/upload";
      if (e.target.files[0].size < Math.pow(1024, 2) * 30) {
          if(e.target.files[0].type.indexOf("image/") === 0){
        // tempSrc.value = newsrc;
        // console.log(tempSrc.value);
        axios.post(url, data, config).then((res) => {
          if (res.status === 200) {
            ElMessage.success("上传图片成功");
            if(ind!=-1){
              dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = res.data.data.path
              dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = res.data.data.url
            }else{
              dynamic.data.enterpriseQualificationListVOS[index].value1 = res.data.data.path
              dynamic.data.enterpriseQualificationListVOS[index].value2 = res.data.data.url
            }
          } else {
            ElMessage.warning("上传图片失败");
          }
        });
        }else{
          ElMessage.warning("请上传图片格式！");
        tempSrc.value = avatar.value;
        }
      } else {
        ElMessage.warning("图像应小于30MB");
        tempSrc.value = avatar.value;
      }
    };

    // 删除图片
    const close_img = (index,ind)=>{
      if (ind == -1) {
        dynamic.data.enterpriseQualificationListVOS[index].value2 = ''
        dynamic.data.enterpriseQualificationListVOS[index].value1 = ''
      } else {
        dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value2 = ''
        dynamic.data.enterpriseQualificationListVOS[index].memberSupplyDTOList[ind].value1 = ''
      }
    }

    const gologin_flag = ref(false);
    //通过replace方式跳转页面
    const goToPage = (path,type) => {
        var reg = /\s+/g;
       // 企业全称
       if (memberList.enterpriseName == '' || memberList.enterpriseName.trim() == '') {
          ElMessage.warning("请填写企业全称！")
          return
        }
        if (memberListInfo.receiveName == '' || memberListInfo.receiveName.trim() == '') {
          ElMessage.warning("请填写收货人姓名！")
          return
        }
        if (memberListInfo.contactMobile == '' || memberListInfo.contactMobile.trim() == '') {
          ElMessage.warning("请填写收货电话！")
          return
        }
        if(checkMobile(memberListInfo.contactMobile)!=true){
          ElMessage.warning("请填写正确的收货电话！")
          return
        }
        if (memberListInfo.area.length==0) {
          ElMessage.warning("请选择所在地区！")
          return
        }
        if ((dynamic.data.enterpriseTypeId=='13' || dynamic.data.enterpriseTypeId=='14' || dynamic.data.enterpriseTypeId=='15' ) && (memberListInfo.addressDetail == '' || memberListInfo.addressDetail.trim() == '')) {
          ElMessage.warning("请填写详细地址！")
          return
        }
        // // 单位地址
        // if (memberList.enterpriseAddress == '' || memberList.enterpriseAddress.trim() == '') {
        //   ElMessage.warning("请填写单位地址！")
        //   return
        // }
        // // 发票抬头
        // if (memberList.invoiceTitle == '' || memberList.invoiceTitle.trim() == '') {
        //   ElMessage.warning("请填写发票抬头！")
        //   return
        // }
        // // 纳税人识别号
        // if (memberList.taxCode == '' || memberList.taxCode.trim() == '') {
        //   ElMessage.warning("请填写纳税人识别号！")
        //   return
        // }
        // // let taxReg = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
        // // if(!taxReg.test(memberList.taxCode)){
        //     // ElMessage.warning("请填写正确的纳税人识别号！")
        // //   return
        // // }
        // // 注册地址
        // if (memberList.registerAddr == '' || memberList.registerAddr.trim() == '') {
        //   ElMessage.warning("请填写注册地址！")
        //   return
        // }
        // // 注册电话
        // if (memberList.registerPhone == '' || memberList.registerPhone.trim() == '') {
        //   ElMessage.warning("请填写注册电话！")
        //   return
        // }
        // // if(checkMobile(memberList.registerPhone)!=true){
        // //   ElMessage.warning("请填写正确的注册电话！")
        // //   return
        // // }
        // // 开户银行
        // if (memberList.bankName == '' || memberList.bankName.trim() == '') {
        //   ElMessage.warning("请填写开户银行！")
        //   return
        // }
        // // 银行账户
        // if (memberList.bankAccount == '' || memberList.bankAccount.trim() == '') {
        //   ElMessage.warning("请填写银行账户！")
        //   return
        // }
        // // 收票人姓名
        // if (memberList.receiverName == '' || memberList.receiverName.trim() == '') {
        //   ElMessage.warning("请填写收票人姓名！")
        //   return
        // }
        // // 收票人电话
        // if (memberList.receiverMobile == '' || memberList.receiverMobile.trim() == '') {
        //   ElMessage.warning("请填写收票人电话！")
        //   return
        // }
        // // if(checkPhone(memberList.receiverMobile)!=true){
        //     // ElMessage.warning("请填写正确的注册电话！")
        // //   return
        // // }
        // // 收票人地址
        // if (memberList.receiverAddress == '' || memberList.receiverAddress.trim() == '') {
        //   ElMessage.warning("请填写收票人地址！")
        //   return
        // }

        for (let i = 0; i < dynamic.data.enterpriseQualificationListVOS.length; i++) {
          let item = dynamic.data.enterpriseQualificationListVOS[i]
          if (item.isRequired == 1) {
            if (item.formatType == 2 && item.value1.trim().length == 0) {
              ElMessage.warning('请填写' + item.enterpriseQualificationName)
              return
            }
            if (item.formatType == 3 && item.value1 == 1) {
              if (item.value2.length == 0) {
                ElMessage.warning('请选择' + item.enterpriseQualificationName)
                return
              }
            }
            if (item.formatType == 1 && item.value1.length == 0) {
              ElMessage.warning('请上传' + item.enterpriseQualificationName)
              return
            }
          }
          for (let j = 0; j < item.memberSupplyDTOList.length; j++) {
            let it = item.memberSupplyDTOList[j]
            if (it.isRequired == 1) {
              if (it.formatType == 2 && it.value1.trim().length == 0) {
                ElMessage.warning('请填写' + it.enterpriseQualificationName)
                return
              }
              if (it.formatType == 3 && it.value1 == 1) {
                if (!it.value2) {
                  ElMessage.warning('请选择' + it.enterpriseQualificationName)
                  return

                }
              }
              if (it.formatType == 1 && it.value1.length == 0) {
                ElMessage.warning('请上传' + it.enterpriseQualificationName)
                return
              }
            }
          }
        }
        if(type=='code'){
          let code = ''
          enterprise.data.map(item=>{
            if(item.enterpriseTypeId==companyType.value){
              code = item.agreementCode
            }
          })

            proxy.$get('/v3/system/front/agreement/detail?agreementCode='+code).then(res => {
            if(res.state == 200){
                agreeContent.content = res.data.content
                agreeContent.title = res.data.title
                dialogVisible.value = true
            }
        })

        }else{
             memberList.enterpriseName = memberList.enterpriseName.replace(reg,'')
          let params = {}
        params = memberList
        params.memberQualificationListDynamicAddDTOS = dynamic.data.enterpriseQualificationListVOS
        params.memberReceiveAddressOa = {}
        params.memberReceiveAddressOa.addressAll =  cascaderAddr.value.getCheckedNodes()[0].pathLabels.join("");//组合
        params.memberReceiveAddressOa.provinceCode = memberListInfo.area[0]//省
        params.memberReceiveAddressOa.cityCode = memberListInfo.area[1]//市
        params.memberReceiveAddressOa.districtCode = memberListInfo.area[2]//区
        params.memberReceiveAddressOa.receiveName = memberListInfo.receiveName //收货人
        params.memberReceiveAddressOa.receiveTel = memberListInfo.contactMobile//收货电话
        params.memberReceiveAddressOa.detailAddress = memberListInfo.addressDetail//详细地址
        params.enterpriseTypeId = dynamic.data.enterpriseTypeId
        params.enterpriseTypeName = dynamic.data.enterpriseTypeName
        let url='';
        if(route.query.type==1){
          url = '/v3/member/front/memberEnterPrise/apply/update'
          // params.receiveInvoiceAddressId = receiveInvoiceAddressId.value
          params.memberEnterpriseId = memberEnterpriseId.value
          // params.invoiceId = invoiceId.value
        }else{
          url = '/v3/member/front/memberEnterPrise/apply/add'
        }
        if(gologin_flag.value){
          return
        }
        gologin_flag.value=true
        proxy.$post(url, params, "json")
          .then((res) => {
            if (res.state == 200) {
              gologin_flag.value = false
            }else{
              gologin_flag.value = false
              ElMessage.warning(res.msg)
            }
          });
          // 直接重定向到成功页面
          ElMessageBox.confirm('经营信息已提交，请耐心等待审核结果，详情可咨询商城客服', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '关闭',
            type: 'success',
          }).then(
              router.replace("/member/index")
          )
        }

    };


    return {
      choosearea,
      choosecity,
      province,
      L,
      changepic,
      tempSrc,
      rander,
      rander2,
      rander3,
      enterprise,//企业类型
      aptit_radio,//长期
      dynamic,//开票信息
      manageList,//编辑
      close_img,//删除图片
      receiveInvoiceAddressId,//发票收获人地址id
      memberEnterpriseId,//会员资质信息id
      invoiceId,//	发票id
      enterpriseName,
      receiveName,
      contactMobile,
      clearInputVal,
      addressDetail,
      cardNumber1,
      cardNumber2,
      cardNumber3,
      remarks1,
      remarks2,
      remarks3,
      goToPage,
      errorMsg,
      wxEnable,
      tirggerFile,
      choosarea,
      memberInfo,
      cascaderProps,
      getInvoicing,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1,
      go_tem,
      value2,
      value3,
      provincevalue,
      city,
      cityvalue,
      areavalue,
      areas,
      // checknumber,
      certificateCards,
      cardInp,
      remarkInp,
      locale,
      companyType,
      company_type,
      long_term,
      memberList,
      dialogVisible,
      agreeContent,
      areaDataArray,
      cascaderAddr,
      memberListInfo,
      gologin_flag
      // select: { province: "广东省", city: "广州市", area: "海珠区" },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "../../../style/businessInfo.scss";
.dialogVisible_btn{
  width: 100px;
  height: 35px;
  border-radius: 200px;
  background-color: rgba(248, 64, 54, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  text-align: center;
  font-family: Roboto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}
.dialogVisible_btn1{
  width: 100px;
  height: 35px;
  border-radius: 200px;
  background-color: rgb(165, 164, 164);
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  text-align: center;
  font-family: Roboto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: wait;
}
.top .item {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;

  &:after {
    display: none !important;
  }
}

.submit_qualification{
  display: flex;
  width: 840px;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 20px;
  p{
    font-size: 18px;
  }
  .qualification{
    color: #F91F1F;
    cursor: pointer;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.bindMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .msg_title {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 26px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  .msg_content {
    margin-top: 30px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  .msg_tip {
    margin-top: 12px;
    line-height: 20px;
  }

  .go_bind_on {
    margin-top: 30px;
    width: 120px;
    height: 36px;
    background: #fc1c1c;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }

  .go_bind_update {
    margin-left: 20px;
    margin-top: 30px;
    width: 120px;
    height: 36px;
    background: #999;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 186px;
  height: 44px;
  margin-right: 17px;
  color: #101010;
  font-size: 14px;
  font-family: Microsoft Yahei;
  // border: 1px solid #bbbbbb;
  .el-icon-arrow-up:before {
    color: #bbbbbb;
    font-weight: 600;
  }
}
.tit_bom {
  font-size: 16px;
  color: rgb(253, 0, 0);
  text-align: left;
  line-height: 35px;
  margin-left: 30px;
  margin-right: 30px;
}
</style>
<style lang="scss">
.sld_Business_o{
  .el-cascader{
    width: 592px !important;
    .el-input{
      width: 100% !important;
    }
    ::placeholder {
      color: #757575 !important;// 如果有时不生效，可能是权限不够，加一个!important即可
    }
    .el-input__inner{
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: 10px !important;
      color: #000;
    }
  }
 .el-radio-group{
  display: flex !important;
  padding-top: 24px !important;
 }
 .el-radio__label{
  width: auto !important;
 }
 .afterSales{
  white-space: pre-wrap !important;
 }
}
.tem_cla {
    color: rgba(23, 123, 255, 1);
font-size: 18px;
text-align: left;
font-family: SourceHanSansSC-regular;
cursor: pointer;
}
</style>
