<!--登录弹框 @zjf-2021-01-08-->
<template>
    <div class="sld_login_modal">
        <el-dialog title="" :model-value="modalVisible" :close-on-click-modal="false" :show-close="false">
            <div class="sld_login_content">
                <span class="iconfont icon-shanchutupian close_icon" @click="closeModal" />
                <div class="login">
                    <div class="login_box">
                        <div class="top">
                            <div :class="{item:true,active:loginType == 1}" @click="changeLoginType(1)">{{L['账号登录']}}
                            </div>
                            <div :class="{item:true,active:loginType == 2}" @click="changeLoginType(2)">{{L['手机登录']}}
                            </div>
                        </div>
                        <div class="center">
                            <template v-if="loginType == 1">
                                <div class="item account">
                                    <span style="color: #BBB;font-size: 19px;padding-top: 7px"
                                        class="icon iconfont icon-wode"></span>
                                    <input type="text" v-model="name" :placeholder="L['请输入账号/手机号']" class="input">
                                    <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                                        <span style="color: #bbb;" class=" iconfont icon-cuowu"></span>
                                    </div>
                                </div>
                                <div class="item password">
                                    <span style="color: #BBB;font-size: 21px;padding-top: 7px"
                                        class="icon iconfont icon-mima1"></span>
                                    <input type="password" v-model="password" :placeholder="L['请输入密码']" class="input">
                                    <div data-type="password" class="cancel" @click="clearInputVal('password')">
                                        <span style="color: #bbb" class="iconfont icon-cuowu"></span>
                                    </div>
                                </div>
                            </template>
                            <template v-if="loginType == 2">
                                <div class="item account">
                                    <span style="color: #BBB;font-size: 21px;padding-top: 7px"
                                        class="icon iconfont icon-shouji2"></span>
                                    <input type="text" v-model="name" :placeholder="L['请输入手机号']" class="input">
                                    <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                                        <span style="color: #bbb;" class=" iconfont icon-cuowu"></span>
                                    </div>
                                </div>
                                <div class="item password">
                                    <span style="color: #BBB;font-size: 18px;padding-top: 8px"
                                        class="icon iconfont icon-yanzhengma2"></span>
                                    <input type="text" v-model="password" :placeholder="L['请输入验证码']" class="input">
                                    <a href="javascript:void(0);" class="send_code"
                                        @click="getSmsCode">{{countDownM?(countDownM+L['s后获取']):L['获取验证码']}}</a>
                                </div>
                            </template>
                            <div class="error">
                                <span v-if="errorMsg" style="color: #e1251b;font-size: 14px"
                                    class="iconfont icon-jubao"></span>
                                {{errorMsg}}
                            </div>
                            <a href="javascript:void(0)" @click="login" class="login_btn">{{L['登录']}}</a>
                        </div>
                        <div
                            :class="{bottom:true, flex_row_between_center:wxEnable==1, flex_row_end_center:wxEnable!=1}">
                            <img src="@/assets/wechat_login.png" alt="" @click="wetChatLogin" v-if="wxEnable==1">
                            <div style="display: flex;">
                                <router-link tag="a" :to="`/register`">
                                    {{L['立即注册']}}
                                </router-link>
                                <router-link tag="a" :to="`/pwd/forget`">
                                    {{L['忘记密码']}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { ref, getCurrentInstance, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router'
    export default {
        name: 'SldLoginModal',
        components: {},
        props: ['visibleFlag'],
        setup(props, { emit }) {
            const modalVisible = ref(props.visibleFlag);
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const store = useStore();
            const route = useRoute()
            const name = ref('');//用户名
            const errorMsg = ref();//错误提示
            const password = ref('');//密码
            const loginType = ref(1);//登陆类型：1-账号密码登陆，2-手机验证码登陆
            const countDownM = ref(0);//短信验证码倒计时
            const timeOutId = ref('');//定时器的返回值
            const wxEnable = ref('')
            const pcAppid = ref('')
            const fromurl = ref('')
            const login = () => {
                let param = {};
                param.username = name.value;
                param.password = password.value;
                param.loginType = loginType.value;
                if (param.loginType == 1) {
                    //账号、手机号的验证
                    if (!param.username) {
                        errorMsg.value = L['请输入账号/手机号'];
                        return false;
                    } else {
                        let checkName = proxy.$checkMemberName(param.username);
                        if (checkName !== true) {
                            //继续验证是否是手机号
                            let checkMobile = proxy.$checkMobile(param.username);
                            if (checkMobile !== true) {
                                errorMsg.value = L['请输入正确的账号/手机号'];
                                return false;
                            }
                        }
                    }

                    //密码校验
                    if (!param.password) {
                        errorMsg.value = L['请输入密码'];
                        return false;
                    }
                    // } else {
                    //     let checkPwd = proxy.$checkPwd(param.password);
                    //     if (checkPwd !== true) {
                    //         errorMsg.value = checkPwd;
                    //         return false;
                    //     }
                    // }
                } else {
                    //手机号验证
                    if (!param.username) {
                        errorMsg.value = L['请输入手机号'];
                        return false;
                    } else {
                        let checkMobile = proxy.$checkMobile(param.username);
                        if (checkMobile !== true) {
                            errorMsg.value = checkMobile;
                            return false;
                        }
                    }

                    //验证码校验
                    if (!param.password) {
                        errorMsg.value = L['请输入短信验证码'];
                        return false;
                    } else {
                        let checkSmsCode = proxy.$checkSmsCode(param.password);
                        if (checkSmsCode !== true) {
                            errorMsg.value = checkSmsCode;
                            return false;
                        }
                    }
                }
                //如果未登录vuex中有购物车数据，需同步到该用户
                let cartInfo = [];
                let cartListData = store.state.cartListData;
                if (JSON.stringify(cartListData) != '{}') {
                    cartListData.storeCartGroupList.map(cartListItem => {
                        cartListItem.promotionCartGroupList.map(promotItem => {
                            promotItem.cartList.map(cartItem => {
                                cartInfo.push({
                                    productId: cartItem.productId,
                                    buyNum: cartItem.buyNum
                                })
                            })
                        })
                    })
                }
                param.cartInfo = JSON.stringify(cartInfo);
                proxy.$post('v3/frontLogin/oauth/token', param).then(res => {
                    if (res.state == 200) {
                        //将用户信息存缓存，并跳转到首页
                        localStorage.setItem('access_token', res.data.access_token);
                        localStorage.setItem('refresh_token', res.data.refresh_token);
                        localStorage.setItem('time', new Date().getTime().toString());//存储refresh_token更新时间
                        //获取用户信息，并同步信息到vuex
                        proxy.$get('/v3/member/front/member/getInfo').then(res => {
                            if (res.state == 200) {
                                emit('refreshInfo')
                                store.commit('updateMemberInfo', res.data);
                                proxy.$getLoginCartListData(); //更新购物车数据
                                closeModal();
                            }
                        })
                    } else {
                        //提示错误
                        errorMsg.value = res.msg
                    }

                })
            }
            //清空输入框内容
            const clearInputVal = (type) => {
                if (type == 'name') {
                    name.value = '';
                } else if (type == 'password') {
                    password.value = '';
                }
            }
            //登录方式切换
            const changeLoginType = (type) => {
                loginType.value = type;
                name.value = '';
                password.value = '';
                errorMsg.value = '';
            }

            //获取短信验证码
            const getSmsCode = () => {
                if (countDownM.value) {
                    return false;
                }
                let checkMobile = proxy.$checkMobile(name.value);
                if (checkMobile !== true) {
                    errorMsg.value = checkMobile;
                } else {
                    let param = {};
                    param.mobile = name.value;
                    param.type = 'free';
                    proxy.$get('v3/msg/front/commons/smsCode', param).then(res => {
                        if (res.state == 200) {
                            countDownM.value = 60;
                            countDown();
                        } else {
                            //提示错误
                            errorMsg.value = res.msg
                        }

                    })
                }
            }
            //倒计时
            const countDown = () => {
                countDownM.value--;
                if (countDownM.value == 0) {
                    clearTimeout(timeOutId.value);
                } else {
                    timeOutId.value = setTimeout(countDown, 1000);
                }
            }
            watchEffect(() => {
                modalVisible.value = props.visibleFlag;
                if (modalVisible.value == false) {
                    document.body.style.overflow = "";
                } else {
                    getWxEnbale()
                    fromurl.value = window.location.origin + route.fullPath
                }
            })


            // 微信二维码登录
            const wetChatLogin = () => {
                let href = `https://open.weixin.qq.com/connect/qrconnect?appid=${pcAppid.value}&redirect_uri=${encodeURIComponent(fromurl.value)}&response_type=code&scope=snsapi_login&state=2or3m#wechat_redirect
`
                window.open(href, "_blank")

            }



            const getWxEnbale = () => {
                proxy.$get('v3/system/front/setting/getSettings', { names: 'login_wx_pc_is_enable,login_wx_pc_appid' }).then(res => {
                    if (res.state == 200) {
                        wxEnable.value = res.data[0]
                        pcAppid.value = res.data[1]
                    }
                })
            }





            //关闭登录modal
            const closeModal = () => {
                emit('closeLoingModal');
            }
            return {
                modalVisible,
                loginType,
                L,
                closeModal,
                login,
                name,
                password,
                clearInputVal,
                errorMsg,
                changeLoginType,
                getSmsCode,
                countDownM,
                wetChatLogin,
                wxEnable
            };
        }
    }
</script>

<style lang="scss">
    @import '../style/loginModal.scss';

    .sld_login_modal {
        .el-dialog {
            width: 376px !important;
        }

        .el-dialog__body {
            padding: 0;
        }

        .el-dialog__headerbtn {
            z-index: 999;
        }
    }
</style>